import { useContext, useEffect, useState } from "react";
import Input from "../UI/Input";
import ImgInput from "../UI/ImgInput";
import Button from "../UI/Button";
import { MSG_CONSTANTS } from "../../constants/msg.constants";
import { UserContext } from "../../store/user.context";
import { useInvoiceQuery } from "../../hooks/useCodeQuery";
import Header from "../UI/Header";
import SaveContinue from "../../assests/buttons/save and continue.png";
import textImg from "../../assests/texts/Text invoice 1.webp";
import puzzle2 from "../../assests/backgrounds/puzzle invoice 1.webp";
import puzzle1 from "../../assests/backgrounds/puzzle invoice 2.webp";
import popupMsg1 from "../../assests/popups/invoice Pop up_1.webp";
import { CODE_CONSTANTS } from "../../constants/code.constants";
export default function Invoice({
  activateModal,
  activateLoader,
  handleSwitch,
}) {
  const userCtx = useContext(UserContext);
  const [invoiceData, setInvoiceData] = useState({
    code: userCtx.code,
    num: userCtx.invoiceData.num ?? "",
    sum: userCtx.invoiceData.sum ?? "",
    src: userCtx.invoiceData.src ?? "",
    base64: userCtx.invoiceData.base64 ?? "",
  });
  // console.log(userCtx.code);
  const {
    data: updateInvoiceStatus,
    mutate: sendInvoiceData,
    status,
  } = useInvoiceQuery(invoiceData.code);
  const handleInvoiceDataChanged = (input, value) => {
    setInvoiceData((prev) => {
      const newData = { ...prev };
      newData[input] = input === "base64" ? value : value.target.value;
      return newData;
    });
  };
  const handleSaveData = () => {
    const isValid = userCtx.validateInvoice(invoiceData);
    if (isValid) {
      userCtx.updateInvoice(invoiceData);
      sendInvoiceData({ ...invoiceData, status: userCtx.status });
    } else {
      let missingCred;
      if (!invoiceData.num) missingCred = "מס' חשבונית";
      else if (!invoiceData.sum) missingCred = "סכום חשבונית";
      else if (!invoiceData.base64) missingCred = "תמונת חשבונית";
      if (missingCred) activateModal(MSG_CONSTANTS.MISSING_CRED + missingCred);
    }
  };

  useEffect(() => {
    // console.log(updateInvoiceStatus);
    if (updateInvoiceStatus && !updateInvoiceStatus?.msg) {
      if (updateInvoiceStatus?.status === CODE_CONSTANTS.ERROR) {
        activateModal(MSG_CONSTANTS.ERROR);
      } else {
        if (userCtx.status == CODE_CONSTANTS.NOT_USED)
          userCtx.updateStatus(CODE_CONSTANTS.INVOICE_DATA);
        handleSwitch(2);
      }
    } else if (updateInvoiceStatus?.msg) {
      activateModal(
        <img
          src={popupMsg1}
          alt="פרטי החשבונית כבר קיימים במערכת, אנא צרו קשר עם שירות הלקוחות במספר 0525015707"
        />
      );
    } else if (updateInvoiceStatus === false) {
      activateModal(MSG_CONSTANTS.ERROR);
    }
  }, [updateInvoiceStatus]);

  useEffect(() => {
    // console.log(status);
    if (status === "pending") {
      activateLoader(true);
    }
    if (status === "success" || status === "idle") {
      activateLoader(false);
    }
  }, [status]);

  // console.log(userCtx);
  return (
    <div
      className={`flex-1 flex flex-col items-center justify-center w-full  md:max-w-2xl max-w-xs mt-20 sm:mt-24 `}
    >
      <Header>חשבונית קנייה</Header>
      <div className="absolute lg:top-0 lg:-right-[7%] md:-right-[15%]  h-min xs:-right-[14%] xs:top-[7%] top-[12%] -right-4 xs:block hidden">
        <img src={puzzle1} className="xl:w-80 md:w-60 xs:w-48 " alt="" />
      </div>
      <div className="absolute lg:bottom-[10%] md:bottom-[5%] sm:-left-8 sm:-bottom-8   xs:-bottom-2 bottom-0 -left-4  h-min xs:block hidden">
        <img src={puzzle2} className="xl:w-44 md:w-32 xs:w-24 " alt="" />
      </div>
      <div className="w-full lg:ml-36 md:ml-24 sm:space-y-4">
        <Input
          value={invoiceData.num}
          onChange={handleInvoiceDataChanged.bind(this, "num")}
          small
          required
          title=":מספר חשבונית"
          type="number"
        />
        <Input
          value={invoiceData.sum}
          onChange={handleInvoiceDataChanged.bind(this, "sum")}
          small
          required
          title=":סכום החשבונית"
          type="number"
        />
        <Input
          value={invoiceData.src}
          onChange={handleInvoiceDataChanged.bind(this, "src")}
          small
          title=":נקנה ב"
        />
      </div>
      <ImgInput
        value={invoiceData.base64}
        handleImageChanged={handleInvoiceDataChanged.bind(this, "base64")}
        title={`${invoiceData.base64 ? "עדכון" : "צירוף"} חשבונית *`}
        showImg
      />
      <img
        src={textImg}
        alt="החשבונית תיבדק בהמשך*"
        className="max-h-7 mb-8 mt-2"
      />
      <div className="flex justify-center items-center lg:absolute bottom-4 -left-10 mb-6">
        <Button small onClick={handleSaveData} btnSrc={SaveContinue} />
      </div>
    </div>
  );
}
