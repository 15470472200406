import { useContext, useState } from "react";
import FormSwitch from "../../components/forms/FormSwitch";
import Invoice from "../../components/forms/Invoice";
import Puzzle from "../../components/forms/Puzzle";
import Info from "../../components/forms/Info";
import { UserContext } from "../../store/user.context";
import Logo from "../../components/UI/Logo";

export default function FormManager({
  activateModal,
  activateLoader,
  isPortrait,
}) {
  const userCtx = useContext(UserContext);
  const [currForm, setCurrForm] = useState(
    userCtx.status ? userCtx.status + 1 : 1
  );

  const handleSwitch = (source) => {
    setCurrForm(source);
  };

  return (
    <div className=" flex-1 flex flex-col justify-center items-center w-11/12 relative ">
      <div className="lg:absolute -top-20 -left-20 z-0">
        <Logo small />
      </div>
      {currForm === 1 ? (
        <Invoice
          activateModal={activateModal}
          activateLoader={activateLoader}
          handleSwitch={handleSwitch}
        />
      ) : currForm === 2 ? (
        <Puzzle
          activateModal={activateModal}
          activateLoader={activateLoader}
          handleSwitch={handleSwitch}
          isPortrait={isPortrait}
        />
      ) : (
        <Info
          activateModal={activateModal}
          activateLoader={activateLoader}
          code={userCtx.code}
          status={userCtx.status}
          isPortrait={isPortrait}
        />
      )}
      <FormSwitch currForm={currForm} handleSwitch={handleSwitch} />
    </div>
  );
}
