export const MSG_CONSTANTS = {
  MISSING_CODE: "קוד לא תקין",
  WRONG_CODE: "הקוד שהוזן אינו קיים במערכת, אנא בידקו את הקוד ונסו שנית",
  USED_CODE: "קוד זה היה בשימוש, אנא בידקו את הקוד שברשותכם ונסו שנית",
  MISSING_CRED: "נא למלא ",
  UNSAVED_CRED: "יש לשמור את הנתונים לפני מעבר שלב",
  ACCEPT_CRED: "הנתונים נשלחו לבדיקה - תודה על השתתפותך",
  USED_INVOICE:
    "פרטי חשבונית זו כבר קיימים במערכת, אנא בידקו שוב את הפרטים שמולאו, במידה ותשובה זו מתקבלת שוב אנא פנו לשירות הלקוחות במספר 123456",
  ERROR: "בעיה בעת הטעינה, אנא נסו שנית או התחברו מחדש",
  LOW_DPI: `התמונה באיכות ירודה, אנא נסו שנית
     *מומלץ להעלות תמונה ברזולוציה גבוהה מהנייד ישירות ועדיף לא מהוואטסאפ`,
  WRONG_MAIL: "מייל לא תקין",
  WRONG_PHONE: "טלפון לא תקין",
  WRONG_HNum: "מס' בית לא תקין",
  AUTO_RES_MAIL:
    "תודה רבה על השתתפותך בפעילות. אנחנו בודקים את נתוני החשבונית, ונעדכן בהקדם אם היא תקינה. בתודה, צוות PersonalPuzzle    ",
  MAIL_FROM: "PersonalPuzzle",
  MAIL_SUBJECT: "הפאזל שלך מ-  PersonalPuzzle מתנת אסם",
};
