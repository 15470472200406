import { useState } from "react";

export default function Button({ onClick, btnSrc }) {
  const [clicking, setClicking] = useState(false);

  const handleMouseDown = () => {
    setClicking(true);
  };
  const handleMouseUp = () => {
    setTimeout(() => {
      setClicking(false);
    }, 75);
  };
  return (
    <button
      className={`flex items-center justify-center overflow-hidden max-w-fit ${
        clicking ? " opacity-75" : " opacity-100"
      }`}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setClicking(false)}
    >
      <img src={btnSrc} alt="שלח" className="sm:h-9 lg:h-10 2xl:h-12" />
    </button>
  );
}
