// console.log(
//   window.location.hostname === "localhost"
//     ? "http://localhost:3000"
//     : "https://osem-server.azurewebsites.net"
// );
export const API_CONSTANTS = {
  FETCH_URL:
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : "https://osem-server.azurewebsites.net",
};
