import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../store/user.context";
import endText1 from "../../assests/texts/end WEB_1920X1080.webp";
import endText2 from "../../assests/texts/end WEB_360X640.webp";
export default function End() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  const userCtx = useContext(UserContext);
  const handleResize = () => {
    if (window.innerWidth <= 640) setIsMobile(true);
    else setIsMobile(false);
    if (window.innerWidth < window.innerHeight) setIsPortrait(true);
    else setIsPortrait(false);
  };
  useEffect(() => {
    userCtx.resetUser();
    // Attach the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`min-h-screen min-w-full flex justify-center items-center  ${
        isMobile ? "bg-end-mobile" : "bg-end-desktop"
      }  bg-center  bg-cover bg-no-repeat`}
    >
      {isMobile ? (
        <img src={endText2} alt="תודה רבה" className={`${isPortrait && ""}`} />
      ) : (
        <img
          src={endText1}
          alt="תודה רבה"
          className={`${isPortrait && "mt-8"}`}
        />
      )}
    </div>
  );
}
