// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIKwYzn5EKmnfsfJgu_mbPjfEJAESTCss",
  authDomain: "osem-puzzle.firebaseapp.com",
  projectId: "osem-puzzle",
  storageBucket: "osem-puzzle.appspot.com",
  messagingSenderId: "326015687795",
  appId: "1:326015687795:web:64a15a84ae1810a85ade06",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
