import { useContext, useEffect, useState } from "react";
import Code from "./Code";
import Modal from "../../components/UI/Modal";
import { CODE_CONSTANTS } from "../../constants/code.constants";
import { useCampaignData, useStatusQuery } from "../../hooks/useCodeQuery";
import { UserContext } from "../../store/user.context";
import FormManager from "./FormManager";
import { MSG_CONSTANTS } from "../../constants/msg.constants";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import wrongCodePopup from "../../assests/popups/Pop up_1.webp";
import usedCodePopup from "../../assests/popups/Pop up_2.webp";
import contactMsgPopup from "../../assests/popups/Pop up_3.webp";
import CampaignEnd from "./CampaignEnd";

export default function Main() {
  const { data: campaignData, isFetching } = useCampaignData();

  // console.log(isFetching);
  // console.log(campaignData);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPortrait, setIsPortrait] = useState(window.innerWidth <= 640);
  const [showContactMsg, setShowContactMsg] = useState(false);

  const userCtx = useContext(UserContext);

  const { data: statusData, mutate: checkStatus } = useStatusQuery();

  const activateModal = (msg) => {
    setModalContent(msg);
    setShowModal(true);
  };
  // console.log(loading);
  const activateLoader = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    if (isFetching) {
      activateLoader(true);
    } else activateLoader(false);
    if (statusData?.status === CODE_CONSTANTS.ERROR || statusData === false) {
      activateModal(MSG_CONSTANTS.ERROR);
    } else if (statusData) {
    }
  }, [statusData, isFetching]);

  const handleResize = () => {
    if (window.innerWidth <= 640) setIsPortrait(true);
    else setIsPortrait(false);
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCodeCheck = async (code) => {
    if (code && code.length > 0) {
      setLoading(true);
      checkStatus(code);
    } else {
      activateModal(MSG_CONSTANTS.MISSING_CODE);
    }
    userCtx.updateCode(code);
  };

  useEffect(() => {
    // console.log(statusData);
    if (statusData?.status >= CODE_CONSTANTS.UNKNOWN) {
      // console.log("inside use effect");
      userCtx.updateStatus(statusData.status);

      if (statusData.status === CODE_CONSTANTS.UNKNOWN) {
        activateModal(
          <img
            src={showContactMsg ? contactMsgPopup : wrongCodePopup}
            alt="קוד לא תקין"
          />
        );
        setShowContactMsg((prev) => !prev);
      }

      if (statusData.status >= CODE_CONSTANTS.INFO_DATA) {
        activateModal(
          <img
            src={showContactMsg ? contactMsgPopup : usedCodePopup}
            alt="קוד שומש כבר"
          />
        );
        setShowContactMsg((prev) => !prev);
      } else if (statusData?.status > CODE_CONSTANTS.UNKNOWN) {
        // console.log(statusData);
        userCtx.updateInvoice({
          base64: statusData.invoiceBase64,
          num: statusData.invoiceNum,
          sum: statusData.invoiceSum,
          src: statusData.invoiceSrc,
        });
        userCtx.updatePuzzle({
          parts: statusData.puzzleParts,
          typeId: statusData.puzzleTypeId,
          link: statusData.puzzleBase64,
        });
      }
    } else if (statusData?.status === CODE_CONSTANTS.ERROR) {
      activateModal(MSG_CONSTANTS.ERROR);
    }
    setLoading(false);
  }, [statusData]);
  // console.log(statusData);

  const component =
    userCtx.status === CODE_CONSTANTS.NOT_USED ||
    userCtx.status === CODE_CONSTANTS.INVOICE_DATA ||
    userCtx.status === CODE_CONSTANTS.PUZZLE_DATA ? (
      <FormManager
        activateModal={activateModal}
        activateLoader={activateLoader}
        isPortrait={isPortrait}
      />
    ) : (
      <Code
        onSend={handleCodeCheck}
        isPortrait={isPortrait}
        status={campaignData?.campaignStatus}
      />
    );

  return (
    <LoadingOverlayWrapper active={loading} spinner text="...נא להמתין">
      {/* <div className="min-h-screen bg-cover bg-center  bg-fixed bg-gradient-to-b from-tertiary3 via-tertiary4 to-tertiary5  flex items-center justify-center"> */}
      <div
        className={`relative min-h-screen flex justify-center bg-desktop ${
          isPortrait && "bg-mobile"
        } bg-cover bg-center bg-no-repeat overflow-hidden ${
          campaignData?.campaignStatus == 1 && "hidden"
        }`}
      >
        <div className="min-h-full flex items-center flex-col p-2 sm:py-10 sm:px-16 w-full">
          {component}
          <Modal
            content={modalContent}
            show={showModal}
            buttonText="אישור"
            buttonFunction={() => setShowModal(false)}
          />
        </div>
      </div>
      <CampaignEnd status={campaignData?.campaignStatus} />
    </LoadingOverlayWrapper>
  );
}
