import { useContext, useEffect, useState } from "react";
import ImgInput from "../UI/ImgInput";
import { UserContext } from "../../store/user.context";
import SelectInput from "../UI/SelectInput";
import {
  usePuzzleOptionsQuery,
  usePuzzleQuery,
} from "../../hooks/useCodeQuery";
import { CODE_CONSTANTS } from "../../constants/code.constants";
import { MSG_CONSTANTS } from "../../constants/msg.constants";
import Button from "../UI/Button";
import ImageCropper from "./ImageCropper";
import Header from "../UI/Header";
import Preview from "../../assests/buttons/preview.png";
import PuzzlePreview from "./PuzzlePreview";
import puzzle1 from "../../assests/backgrounds/puzzle puzzle 2.webp";
import puzzle2 from "../../assests/backgrounds/puzzle puzzle 1.webp";
import puzzle3 from "../../assests/backgrounds/puzzle puzzle 4.webp";
import puzzle4 from "../../assests/backgrounds/puzzle puzzle 3.webp";
import textImg from "../../assests/texts/Text puzzle 1.webp";
import popup1 from "../../assests/popups/puzzle Pop up_1.webp";
const a4PaperWidth = 8.3; // A4 paper width in inches
const a4PaperHeight = 11.7; // A4 paper height in inches

export default function Puzzle({
  activateModal,
  activateLoader,
  handleSwitch,
  isPortrait,
}) {
  const userCtx = useContext(UserContext);
  // console.log(userCtx);
  const { data: puzzleOptions, isFetching } = usePuzzleOptionsQuery();
  const [puzzleData, setPuzzleData] = useState({
    code: userCtx.code,
    parts: null,
    typeId: null,
    base64: null,
    link: userCtx.puzzleData?.link,
    uploadedImage: null,
  });
  // console.log(userCtx.puzzleData, puzzleData);
  const {
    data: updatePuzzleStatus,
    mutate: sendPuzzleData,
    status,
  } = usePuzzleQuery(userCtx.code);

  const [showPreview, setShowPreview] = useState(!!userCtx.puzzleData?.link);
  // const [uploadedImage, setUploadedImage] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });
  const [hidePuzzleData, setHidePuzzleData] = useState(false);

  const handleShowPreview = () => {
    activateLoader(true);
    const isValid = userCtx.validatePuzzle(puzzleData);
    if (
      isValid &&
      (puzzleData.uploadedImage ||
        userCtx.puzzleData?.link ||
        puzzleData.base64)
    ) {
      // userCtx.updatePuzzle(puzzleData);
      // sendPuzzleData({ ...puzzleData, status: userCtx.status });
      setShowPreview(true);
    } else {
      let missingCred;
      if (!puzzleData.parts) missingCred = "מס' חלקים";
      else if (!puzzleData.typeId) missingCred = "סוג";
      else if (!puzzleData.uploadedImage && !userCtx.puzzleData?.link)
        missingCred = "תמונת פאזל";

      if (missingCred) activateModal(MSG_CONSTANTS.MISSING_CRED + missingCred);
    }
    activateLoader(false);
  };

  const goBack = () => {
    setShowPreview(false);
    // handlePuzzleDataChanged("base64", null);
    setHidePuzzleData(false);
  };
  // Function to get image size from base64 string
  const getImageSize = (base64String) => {
    const img = new Image();
    img.src = base64String;
    img.alt = "";
    img.onload = () => {
      setImageSize(img);
    };
  };
  useEffect(() => {
    if (puzzleData.uploadedImage) getImageSize(puzzleData.uploadedImage);
  }, [puzzleData.uploadedImage]);

  const calculateDPI = (width, height, widthInches, heightInches) => {
    // Calculate DPI based on width, height, and actual inches
    const dpiX = width / widthInches;
    const dpiY = height / heightInches;

    // Use average DPI
    const averageDPI = (dpiX + dpiY) / 2;

    return averageDPI;
  };

  useEffect(() => {
    if (imageSize.width > 0 && imageSize.height > 0)
      if (
        calculateDPI(
          imageSize.width,
          imageSize.height,
          a4PaperWidth,
          a4PaperHeight
        ) < 180
      ) {
        activateModal(<img src={popup1} alt="" />);
        // setUploadedImage(null);
        setPuzzleData((prev) => ({ ...prev, base64: "", uploadedImage: null }));
      }
  }, [imageSize]);

  // console.log(puzzleData);

  const handlePuzzleDataChanged = (input, value) => {
    // if (input !== "uploadedImage") {
    setPuzzleData((prev) => {
      const newData = { ...prev };
      newData[input] = value;
      return newData;
    });
    // } else {
    //   setUploadedImage(value);
    //   setPuzzleData((prev) => ({ ...prev, base64: "" }));
    // }
  };

  useEffect(() => {
    if (isFetching) {
      activateLoader(true);
    } else activateLoader(false);
    if (
      puzzleOptions?.status === CODE_CONSTANTS.ERROR ||
      puzzleOptions === false
    )
      activateModal(MSG_CONSTANTS.ERROR);
    else if (puzzleOptions?.partOptions && puzzleOptions?.typeOptions) {
      const userChosenParts = puzzleOptions.partOptions.filter(
        (option) => option.label.trim() === userCtx.puzzleData.parts
      );
      const userChosenTypeId = puzzleOptions.typeOptions.filter(
        (option) => option.value === userCtx.puzzleData.typeId
      );
      setPuzzleData((prev) => ({
        ...prev,
        base64: userCtx.puzzleData?.base64,
        parts: userChosenParts.length
          ? userChosenParts[0]
          : puzzleOptions?.partOptions[0],
        typeId: userChosenTypeId.length
          ? userChosenTypeId[0]
          : puzzleOptions?.typeOptions[0],
      }));
    }
  }, [puzzleOptions, isFetching, userCtx.puzzleData]);

  const handleSaveData = () => {
    // activateLoader(true);
    userCtx.updatePuzzle(puzzleData);
    sendPuzzleData({ ...puzzleData, status: userCtx.status });
    // console.log(puzzleData);
  };

  useEffect(() => {
    if (updatePuzzleStatus && !updatePuzzleStatus?.msg) {
      if (updatePuzzleStatus?.status === CODE_CONSTANTS.ERROR) {
        activateModal(MSG_CONSTANTS.ERROR);
      } else {
        if (userCtx.status == CODE_CONSTANTS.INVOICE_DATA)
          userCtx.updateStatus(CODE_CONSTANTS.PUZZLE_DATA);
        handleSwitch(3);
      }
    } else if (updatePuzzleStatus?.msg) {
      activateModal(MSG_CONSTANTS.USED_INVOICE);
    } else if (updatePuzzleStatus === false) {
      activateModal(MSG_CONSTANTS.ERROR);
    }
  }, [updatePuzzleStatus]);

  useEffect(() => {
    // console.log({ status });
    if (status === "pending") {
      activateLoader(true);
    }
    if (status === "success" || status === "idle") {
      activateLoader(false);
    }
  }, [status]);

  useEffect(() => {
    if (showPreview) setHidePuzzleData(true);
  }, [showPreview]);

  return (
    <div
      className={`flex-1 flex flex-col items-center justify-center w-full  md:max-w-2xl max-w-xs `}
    >
      {puzzleOptions?.partOptions?.length &&
        puzzleOptions?.typeOptions?.length &&
        !hidePuzzleData && (
          <div className="w-full flex flex-col justify-center items-center space-y-4">
            <Header>בחירת פאזל</Header>
            <div className="absolute -bottom-[5%] lg:-bottom-[5%] xl:-bottom-[6%] -right-[14%] xl:-right-[10%] h-min hidden lg:block">
              <img src={puzzle1} className="xl:w-80  w-52" alt="" />
            </div>
            <div className="w-full lg:ml-36 md:ml-24 sm:space-y-4">
              <SelectInput
                hideIndicators={isPortrait}
                required
                title=":מספר חלקים"
                options={puzzleOptions.partOptions}
                onChange={handlePuzzleDataChanged.bind(this, "parts")}
                value={puzzleData.parts}
                isPortrait={isPortrait}
              />
              <SelectInput
                hideIndicators={isPortrait}
                required
                title=":סוג"
                options={puzzleOptions.typeOptions}
                onChange={handlePuzzleDataChanged.bind(this, "typeId")}
                value={puzzleData.typeId}
                isPortrait={isPortrait}
              />
            </div>
            <ImgInput
              handleImageChanged={handlePuzzleDataChanged.bind(
                this,
                "uploadedImage"
              )}
              title={`${puzzleData.uploadedImage ? "עדכון" : "צירוף"} תמונה *`}
              value={puzzleData.uploadedImage}
            />
            <ImageCropper
              src={puzzleData.uploadedImage}
              show={!!puzzleData.uploadedImage}
              // cropped={puzzleData.base64}
              activateModal={activateModal}
              isPortrait={puzzleData.typeId?.value == 1 ? false : true}
              showPreview={showPreview}
              handlePuzzleDataChanged={handlePuzzleDataChanged}
              code={userCtx.code}
            />
            <div className="absolute h-min hidden lg:block -left-[8%]  top-[35%] ">
              <img src={puzzle2} className="xl:w-60 w-44" alt="" />
            </div>
            <div className="absolute h-min lg:hidden block -left-[12%] sm:-left-[22%] md:-left-[18.8%] top-36 xs:top-20 sm:top-20">
              <img src={puzzle4} className="sm:w-48 xs:w-32 w-24" alt="" />
            </div>
            <div className="absolute h-min lg:hidden block md:-right-16 md:-bottom-10 sm:-right-[18%] xs:-right-[9%] -right-[8%] -bottom-2">
              <img src={puzzle3} className="sm:w-40 xs:w-24 w-16" alt="" />
            </div>
            <img
              src={textImg}
              alt="מומלץ להעלות תמונה באיכות גבוהה*"
              className=" "
            />
            <div className="h-min  lg:absolute bottom-4 -left-10 p-6 lg:p-0">
              <Button onClick={handleShowPreview} btnSrc={Preview} />
            </div>
          </div>
        )}
      {showPreview && (
        <PuzzlePreview
          goBack={goBack}
          handleSaveData={handleSaveData}
          parts={puzzleData.parts?.value + 1}
          orientation={puzzleData.typeId?.value}
          src={puzzleData.base64 || userCtx.puzzleData.link}
          activateLoader={activateLoader}
        />
      )}
    </div>
  );
}
