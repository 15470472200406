import React, { useState } from "react";
import Button from "./Button";
import AddImg from "../../assests/buttons/Attaching a picture.png";
import UpdateImg from "../../assests/buttons/Update picture.png";
export default function ImgInput({ handleImageChanged, value, showImg }) {
  const [imageSrc, setImageSrc] = useState(value);

  const readImageDataUrl = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      // Read the image file as a data URL
      const imageDataUrl = await readImageDataUrl(file);
      // console.log(imageDataUrl);
      setImageSrc(imageDataUrl);
      handleImageChanged(imageDataUrl);
    }
  };
  return (
    <>
      <div className="mt-8">
        <Button
          btnSrc={value ? UpdateImg : AddImg}
          onClick={() => document.getElementById("fileInput").click()}
        />
      </div>

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
      />
      {imageSrc && showImg && (
        <div className="my-4">
          <img
            src={imageSrc}
            alt="Invoice or Puzzle"
            className="max-w-full h-auto"
          />
        </div>
      )}
    </>
  );
}
