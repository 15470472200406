import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../store/user.context";

export default function FormSwitch({ currForm, handleSwitch }) {
  const userCtx = useContext(UserContext);
  const [isInfoDisabled, setIsInfoDisabled] = useState(true);
  const [isPuzzleDisabled, setIsPuzzleDisabled] = useState(true);

  const activeClasses =
    "font-Heebo-bold bg-gradient-to-bl from-tertiary to-primary1 text-xs xs:text-md sm:text-xl text-white";
  const inactiveClasses =
    "bg-gradient-to-bl from-tertiary3 via-tertiary4 to-tertiary5  text-xs xs:text-md sm:text-xl ";
  const disabledClasses =
    "bg-gradient-to-l from-tertiary3 via-tertiary4 to-tertiary5 text-xs xs:text-md sm:text-xl text-tertiary2";

  useEffect(() => {
    setIsInfoDisabled(userCtx.status < 2);
    setIsPuzzleDisabled(userCtx.status < 1);
  }, [userCtx.status]);

  return (
    <div className="mt-auto grid grid-cols-3 divide-x divide-black border border-primary rounded text-primary font-Heebo-semibold  text-center overflow-hidden mb-10 z-0">
      <button
        className={`flex justify-center items-center p-2 text-nowrap ${
          isInfoDisabled
            ? disabledClasses
            : currForm === 3
            ? activeClasses
            : inactiveClasses
        }`}
        onClick={handleSwitch.bind(this, 3)}
        disabled={isInfoDisabled}
      >
        פרטים ומשלוח
      </button>
      <button
        className={`flex justify-center items-center p-2 text-nowrap ${
          isPuzzleDisabled
            ? disabledClasses
            : currForm === 2
            ? activeClasses
            : inactiveClasses
        }`}
        onClick={handleSwitch.bind(this, 2)}
        disabled={isPuzzleDisabled}
      >
        פאזל
      </button>
      <button
        className={`flex justify-center items-center p-2 text-nowrap ${
          currForm === 1 ? activeClasses : inactiveClasses
        }`}
        onClick={handleSwitch.bind(this, 1)}
      >
        חשבונית
      </button>
    </div>
  );
}
