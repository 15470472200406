import React, { useContext, useEffect, useState } from "react";
import Header from "../UI/Header";
import Input from "../UI/Input";
import SelectInput from "../UI/SelectInput";
import {
  useCityQuery,
  useInfoQuery,
  usePhoneQuery,
  useStreetQuery,
} from "../../hooks/useCodeQuery";
import { MSG_CONSTANTS } from "../../constants/msg.constants";
import { CODE_CONSTANTS } from "../../constants/code.constants";
import Button from "../UI/Button";
import SaveAndSend from "../../assests/buttons/Save and send.png";
import { UserContext } from "../../store/user.context";
import { useNavigate } from "react-router-dom";
import puzzle1 from "../../assests/backgrounds/puzzle info 1.webp";
import puzzle2 from "../../assests/backgrounds/puzzle info  2.webp";
import puzzle3 from "../../assests/backgrounds/puzzle info 3.webp";

export default function Info({
  code,
  activateLoader,
  activateModal,
  status,
  isPortrait,
}) {
  const [infoData, setInfoData] = useState({
    firstName: null,
    lastName: null,
    mail: null,
    prePhone: null,
    phone: null,
    city: null,
    street: null,
    houseNum: null,
    apartment: null,
    zip: null,
  });
  const userCtx = useContext(UserContext);

  const { data: citiesOptions, isFetching } = useCityQuery();
  const { data: phonesOptions, isFetchingPhones } = usePhoneQuery();

  const {
    data: streetsOptions,
    mutate: getStreetsOptions,
    status: streetsStatus,
  } = useStreetQuery(infoData?.city);

  const {
    data: sendStatus,
    mutate: sendInfoData,
    status: reqStatus,
  } = useInfoQuery(code);

  useEffect(() => {
    // console.log(status);
    if (streetsStatus === "pending") {
      activateLoader(true);
    }
    if (streetsStatus === "success" || streetsStatus === "idle") {
      activateLoader(false);
    }
  }, [streetsStatus]);

  useEffect(() => {
    // console.log(status);
    if (reqStatus === "pending") {
      activateLoader(true);
    }
    if (reqStatus === "success" || reqStatus === "idle") {
      activateLoader(false);
    }
  }, [reqStatus]);

  useEffect(() => {
    // console.log(citiesOptions);
    if (isFetching) activateLoader(true);
    else activateLoader(false);
    if (
      citiesOptions?.status === CODE_CONSTANTS.ERROR ||
      citiesOptions === false
    )
      activateModal(MSG_CONSTANTS.ERROR);
  }, [citiesOptions, isFetching]);

  useEffect(() => {
    // console.log(phonesOptions);
    if (isFetchingPhones) activateLoader(true);
    else activateLoader(false);
    if (
      phonesOptions?.status === CODE_CONSTANTS.ERROR ||
      phonesOptions === false
    )
      activateModal(MSG_CONSTANTS.ERROR);
  }, [phonesOptions, isFetchingPhones]);

  useEffect(() => {
    if (infoData.city?.label) {
      setInfoData((prev) => ({ ...prev, street: null }));
      activateLoader(true);
      getStreetsOptions(infoData.city.label);
    }
  }, [infoData.city]);

  const handleInfoChanged = (input, value) => {
    // console.log(input, value);
    setInfoData((prev) => {
      const newState = { ...prev };
      newState[input] =
        input === "city" || input === "street" || input === "prePhone"
          ? value
          : value.target.value;
      return newState;
    });
  };

  useEffect(() => {
    if (
      streetsOptions?.status === CODE_CONSTANTS.ERROR ||
      streetsOptions === false
    ) {
      activateModal("בעיה בקבלת רחובות, אנא נסו שנית");
    }
  }, [streetsOptions]);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(sendStatus);
    if (sendStatus?.status === CODE_CONSTANTS.ERROR || sendStatus === false) {
      activateModal("בעיה בשליחת נתונים, אנא נסו שנית");
    } else if (sendStatus) {
      navigate("/end");
    }
  }, [sendStatus]);

  const validateData = () => {
    // console.log(infoData);
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      infoData.firstName &&
      infoData.lastName &&
      infoData.mail &&
      infoData.phone &&
      infoData.prePhone &&
      infoData.city &&
      infoData.street &&
      infoData.houseNum &&
      infoData.mail.match(emailRegex) &&
      infoData.phone.length === 7 &&
      !isNaN(infoData.phone) &&
      !isNaN(infoData.houseNum)
    ) {
      return true;
    } else {
      let missingCred;
      if (!infoData.firstName) missingCred = "שם פרטי";
      else if (!infoData.lastName) missingCred = "שם משפחה";
      else if (!infoData.mail) missingCred = "מייל";
      else if (!infoData.phone || !infoData.prePhone) missingCred = "טלפון";
      else if (!infoData.city) missingCred = "עיר מבחירה";
      else if (!infoData.street) missingCred = "רחוב מבחירה";
      else if (!infoData.houseNum) missingCred = "מס' בית";
      if (missingCred) activateModal(MSG_CONSTANTS.MISSING_CRED + missingCred);
      else {
        if (!infoData.mail.match(emailRegex)) {
          activateModal(MSG_CONSTANTS.WRONG_MAIL);
        } else if (infoData.phone.length !== 7 || isNaN(infoData.phone))
          activateModal(MSG_CONSTANTS.WRONG_PHONE);
        else if (isNaN(infoData.houseNum))
          activateModal(MSG_CONSTANTS.WRONG_HNum);
      }
    }
    return false;
  };

  const handleSendData = () => {
    const isValid = validateData();
    // console.log(isValid);
    if (isValid) {
      sendInfoData({
        ...infoData,
        phone: infoData.prePhone.label + infoData.phone,
        city: infoData.city.label,
        street: infoData.street.label,
        code,
        status,
        text: MSG_CONSTANTS.AUTO_RES_MAIL,
        from: MSG_CONSTANTS.MAIL_FROM,
        subject: MSG_CONSTANTS.MAIL_SUBJECT,
      });
    }
  };

  // console.log(citiesOptions);
  return (
    <div
      className={`flex-1 flex flex-col justify-center items-center w-full max-w-lg sm:space-y-4 `}
    >
      <Header>פרטים למשלוח</Header>
      <div className="absolute 2xl:-right-[13%] xl:-right-[14%] lg:-right-[16.5%] h-min hidden lg:block ">
        <img src={puzzle1} className="2xl:w-[550px] xl:w-96 lg:w-80" alt="" />
      </div>
      <div className="absolute -right-[10.5%] sm:-right-[21%] md:-right-[19.5%] sm:-top-[6%] top-[3%]  h-min lg:hidden xs:block hidden">
        <img src={puzzle2} className="md:w-72 sm:w-52 w-32" alt="" />
      </div>
      <div className="w-full lg:ml-36 md:ml-24 sm:space-y-4">
        <Input
          onChange={handleInfoChanged.bind(this, "firstName")}
          small
          required
          title=":שם פרטי"
          value={infoData.firstName ?? ""}
        />
        <Input
          onChange={handleInfoChanged.bind(this, "lastName")}
          small
          required
          title=":שם משפחה"
          value={infoData.lastName ?? ""}
        />
        <Input
          onChange={handleInfoChanged.bind(this, "mail")}
          small
          required
          title=":מייל"
          value={infoData.mail ?? ""}
        />
        <div className="flex w-full items-center justify-center gap-1">
          <div className="self-end mb-1 w-20">
            <SelectInput
              onChange={handleInfoChanged.bind(this, "prePhone")}
              options={phonesOptions?.length > 0 ? phonesOptions : []}
              value={infoData.prePhone}
              placeholder="קידומת"
              hideIndicators
              isPortrait={isPortrait}
            />
          </div>
          <div className="font-bold text-2xl self-end mb-3 sm:mb-1 sm:self-auto">
            -
          </div>
          <div className="sm:w-[67%] mr-auto w-full">
            <Input
              onChange={handleInfoChanged.bind(this, "phone")}
              small
              required
              title=":טלפון"
              value={infoData.phone ?? ""}
              type="number"
            />
          </div>
        </div>

        <SelectInput
          onChange={handleInfoChanged.bind(this, "city")}
          options={citiesOptions?.length > 0 ? citiesOptions : []}
          required
          title=":עיר"
          value={infoData.city}
          placeholder="בחירה"
          isPortrait={isPortrait}
        />
        <SelectInput
          onChange={handleInfoChanged.bind(this, "street")}
          options={streetsOptions?.length > 0 ? streetsOptions : []}
          required
          title=":רחוב"
          value={infoData.street}
          noOptionsMessage="יש לבחור עיר"
          placeholder="בחירה"
          isPortrait={isPortrait}
        />
        <div className="flex items-center gap-1 justify-start w-full pb-8">
          <div className="basis-2/4 sm:w-44 sm:basis-auto">
            <Input
              onChange={handleInfoChanged.bind(this, "zip")}
              small
              title=":מיקוד"
              value={infoData.zip ?? ""}
              type="number"
            />
          </div>
          <div className="basis-1/4 sm:basis-auto sm:w-28">
            <Input
              onChange={handleInfoChanged.bind(this, "apartment")}
              small
              title=":דירה"
              value={infoData.apartment ?? ""}
            />
          </div>
          <div className="basis-1/4 sm:w-40 sm:basis-auto">
            <Input
              onChange={handleInfoChanged.bind(this, "houseNum")}
              small
              required
              title=":מס' בית"
              value={infoData.houseNum ?? ""}
              type="number"
            />
          </div>
        </div>
      </div>
      <div className="absolute sm:-left-[13%]  sm:-bottom-[5%] -bottom-0 -left-[7%] h-min lg:hidden xs:block hidden ">
        <img src={puzzle3} className="md:w-60 sm:w-40 w-24" alt="" />
      </div>
      <div className="h-min  lg:absolute bottom-4 -left-10 p-6 lg:p-0 ">
        <Button btnSrc={SaveAndSend} onClick={handleSendData} />
      </div>
    </div>
  );
}
