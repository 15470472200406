import ReactModal from "react-modal";
import Button from "./Button";
import closeBtn from "../../assests/buttons/Closed.png";
export default function Modal({
  show,
  content,
  buttonText = "אישור",
  buttonFunction = () => {},
}) {
  return (
    <ReactModal
      appElement={document.getElementById("root")}
      isOpen={show}
      className=" max-h-[80%] md:max-w-[50%] max-w-[80%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-t from-tertiary4 via-tertiary5 to-tertiary4 rounded-md p-4 border flex-1 w-full overflow-auto"
    >
      <div className="flex flex-col items-center text-primary1  overflow-hidden">
        <div className="flex-1 xs:text-base md:text-2xl text-sm font-Heebo-semibold text-center flex justify-center items-center w-full mb-8">
          {content}
        </div>
        <div className="mt-auto w-1/2 flex justify-center items-center">
          <Button
            title={buttonText}
            onClick={() => {
              buttonFunction();
            }}
            small
            btnSrc={closeBtn}
          />
        </div>
      </div>
    </ReactModal>
  );
}
