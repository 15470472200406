import React from "react";

export default function Logo({ small }) {
  return (
    <div className="relative z-0">
      <img
        className={`${
          small
            ? "max-h-44 md:max-h-52 lg:max-h-64 xl:max-h-72 "
            : "max-h-64 sm:max-h-96 2xl:min-h-[400px]"
        } w-auto`}
        src={require("../../assests/logos/WEB Logo_100X100.png")}
        alt="Osem Logo"
      />
    </div>
  );
}
