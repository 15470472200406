import React, { useEffect } from "react";
import Header from "../UI/Header";
import SaveContinue from "../../assests/buttons/save and continue.png";
import Return from "../../assests/buttons/return.png"; // will be replace by go back button
import Button from "../UI/Button";
import horizontal48 from "../../assests/templates/172286 [Converted].webp";
import horizontal24 from "../../assests/templates/172286b [Converted].webp";
import vertical48 from "../../assests/templates/172286 vertical.webp";
import vertical24 from "../../assests/templates/172286b vertical.webp";
export default function PuzzlePreview({
  src,
  handleSaveData,
  goBack,
  parts,
  orientation,
  activateLoader,
}) {
  // console.log(parts, orientation);

  useEffect(() => {
    if (src) activateLoader(false);
    else activateLoader(true);
  }, [src]);

  let puzzleTemplate;
  if (parts + orientation === 2) puzzleTemplate = horizontal24;
  else if (parts === 1) puzzleTemplate = vertical24;
  else if (orientation === 1) puzzleTemplate = horizontal48;
  else puzzleTemplate = vertical48;

  return (
    <div className="mt-8">
      <Header>תצוגה מקדימה</Header>
      <div className="relative flex justify-center items-center w-full  box-border mt-6">
        <img
          src={src}
          alt="User Puzzle"
          className="max-w-full max-h-fit  border-2 rounded-2xl"
        />
        <img src={puzzleTemplate} className="absolute w-full h-full " alt="" />
      </div>
      <div className="flex justify-between items-center my-6">
        <Button onClick={handleSaveData} btnSrc={SaveContinue} />
        <Button onClick={goBack} btnSrc={Return} />
      </div>
    </div>
  );
}
