import Select from "react-select";

export default function SelectInput({
  required,
  title,
  options,
  value,
  onChange,
  noOptionsMessage = null,
  placeholder,
  hideIndicators,
  isPortrait,
}) {
  // console.log(options);
  return (
    <div
      className={`w-full flex flex-col-reverse sm:flex-row justify-start items-center  ${
        title && "sm:gap-2"
      } `}
    >
      {/* <div className="rounded-3xl bg-gradient-to-l from-primary2 via-secondary1 to-secondary p-0.5 shadow-md overflow-hidden"> */}
      <div className="sm:h-14 h-10 sm:max-w-[365px]  w-full">
        <Select
          components={
            hideIndicators && {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }
          }
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage ?? "אין אופציות"}
          options={options}
          value={value}
          isClearable={true}
          isRtl={true}
          isSearchable={true}
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "2.5rem",
              height: isPortrait ? "2.5rem" : "3.5rem",
              borderRadius: "0.75rem",
              backgroundColor: "#f9fafb",
              borderWidth: "2px",
              borderColor: "#083344",
              // width: "100%",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "#172554",
              fontSize: "1rem",
              fontWeight: "400",
            }),
            menu: (provided) => ({
              ...provided,
              color: "#172554",
              fontSize: "1rem",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral0: "#f9fafb",
              neutral60: "#172554",
              neutral5: "#172554",
              primary: "#083344",
              primary25: "#67e8f9",
            },
          })}
          onChange={onChange}
        />
      </div>
      <label className="block font-Heebo-semibold sm:text-xl text-base mb-1 text-right text-primary1 text-nowrap self-end sm:self-center">
        {title} {required && <span className="text-secondary1">*</span>}
      </label>
      {/* </div> */}
    </div>
  );
}
