import axios from "axios";
import { API_CONSTANTS } from "../../constants/api.constants";
import { CODE_CONSTANTS } from "../../constants/code.constants";
import { uploadImage } from "../../firebase/services";

const onError = (error) => {
  console.log(error);
  return { status: CODE_CONSTANTS.ERROR };
};

export const getCampaignStatus = async () => {
  try {
    const status = await axios.get(
      `${API_CONSTANTS.FETCH_URL}/code/campaignStatus`
    );
    return status.data;
  } catch (error) {
    return onError(error);
  }
};

export const getStatus = async (code) => {
  // console.log({ code });

  try {
    const status = await axios.get(`${API_CONSTANTS.FETCH_URL}/code/status`, {
      params: { code },
    });
    // console.log(status.data);
    return status?.data;
  } catch (error) {
    return onError(error);
  }
};

export const updateInvoice = async (data) => {
  try {
    const status = await axios.post(
      `${API_CONSTANTS.FETCH_URL}/code/invoice`,
      data
    );
    // console.log({ status });
    return status?.data;
  } catch (error) {
    return onError(error);
  }
};

export const getPuzzleOptions = async () => {
  try {
    const puzzleOptions = await axios.get(
      `${API_CONSTANTS.FETCH_URL}/code/puzzleOptions`
    );
    // console.log(puzzleOptions);
    return {
      partOptions: puzzleOptions.data.partOptions.map((option, index) => ({
        value: index,
        label: option.PazelPartNum,
      })),
      typeOptions: puzzleOptions.data.typeOptions.map((option) => ({
        value: option.PazelTypeID,
        label: option.PazelType,
      })),
    };
  } catch (error) {
    return onError(error);
  }
};

export const updatePuzzle = async (data) => {
  try {
    const imageUrl = await uploadImage({
      name: data.code,
      base64: data.base64,
    });
    if (imageUrl) {
      const updateRes = await axios.post(
        `${API_CONSTANTS.FETCH_URL}/code/puzzle`,
        {
          code: data.code,
          parts: data.parts?.label.trim(),
          typeId: data.typeId?.value,
          link: imageUrl,
          status: data.status,
        }
      );
      // console.log(updateRes.data);
      return updateRes.data;
    }
    return false;
  } catch (error) {
    return onError(error);
  }
};

export const getCities = async () => {
  try {
    const cities = await axios.get(`${API_CONSTANTS.FETCH_URL}/code/city`);
    return cities.data;
  } catch (error) {
    return onError(error);
  }
};

export const getStreets = async (city) => {
  try {
    const cities = await axios.get(`${API_CONSTANTS.FETCH_URL}/code/street`, {
      params: {
        city,
      },
    });
    return cities.data;
  } catch (error) {
    return onError(error);
  }
};

export const getPhones = async () => {
  try {
    const cities = await axios.get(`${API_CONSTANTS.FETCH_URL}/code/phone`);
    return cities.data;
  } catch (error) {
    return onError(error);
  }
};

export const updateInfo = async (data) => {
  try {
    const res = await axios.post(`${API_CONSTANTS.FETCH_URL}/code/info`, data);
    return res.data;
  } catch (error) {
    return onError(error);
  }
};
