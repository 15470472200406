import React from "react";
// import "../../index.css";
export default function Input({
  title,
  value,
  onChange,
  required,
  placeholder,
  small,
  type = "text",
}) {
  return (
    <div
      className={`z-0 w-full  ${
        small ? "my-1 sm:justify-end justify-center" : "my-4 justify-center"
      } flex  flex-col sm:flex-row-reverse  items-center ${
        title && "sm:gap-2 "
      }`}
    >
      <label
        className={`block text-2xl ${
          small && "sm:text-lg md:text-xl text-base"
        }  font-Heebo-normal  text-right text-primary1 text-nowrap self-end sm:self-center`}
      >
        {title} {required && <span className="text-secondary1">*</span>}
      </label>

      <input
        type={type}
        className={`sm:max-w-[365px]  w-full font-Heebo-normal rounded-xl bg-tertiary5 shadow-md self-end sm:self-center ${
          small
            ? "text-base sm:h-14 h-10 p-2"
            : "text-lg md:text-2xl sm:h-14 h-10 p-4"
        } text-primary1 gradient border-primary1 text-center focus:border-primary2 focus:outline-none border-2 `}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
