import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Main from "./pages/Customer/Main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import UserContextProvider from "./store/user.context.js";
import End from "./pages/Customer/End.js";
import CampaignEnd from "./pages/Customer/CampaignEnd.js";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/end" element={<End />} />
            <Route path="/campaignend" element={<CampaignEnd />} />
          </Routes>
        </Router>

        {/* <ReactQueryDevtools initialIsOpen={true} buttonPosition="bottom-left" /> */}
      </UserContextProvider>
    </QueryClientProvider>
  );
}

export default App;
