import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCampaignStatus,
  getCities,
  getPhones,
  getPuzzleOptions,
  getStatus,
  getStreets,
  updateInfo,
  updateInvoice,
  updatePuzzle,
} from "../services/codes/code.service";

export const useCampaignData = () => {
  return useQuery({
    queryKey: ["campaignStatus"],
    queryFn: getCampaignStatus,
    throwOnError: true,
  });
};

export const useStatusQuery = (code) => {
  return useMutation({
    mutationKey: ["status", code],
    mutationFn: getStatus,
    throwOnError: true,
  });
};

export const useInvoiceQuery = (code) => {
  return useMutation({
    mutationKey: ["invoiceUpdate", code],
    mutationFn: updateInvoice,
    throwOnError: true,
  });
};

export const usePuzzleOptionsQuery = () => {
  return useQuery({
    queryKey: ["puzzleOptions"],
    queryFn: getPuzzleOptions,
    throwOnError: true,
    gcTime: "Infinity",
    staleTime: 1000 * 60,
  });
};

export const usePuzzleQuery = (code) => {
  return useMutation({
    mutationKey: ["puzzleUpdate", code],
    mutationFn: updatePuzzle,
    throwOnError: true,
  });
};

export const useCityQuery = () => {
  return useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
    throwOnError: true,
    gcTime: "Infinity",
    staleTime: 1000 * 60,
  });
};

export const useStreetQuery = (city) => {
  return useMutation({
    mutationKey: ["streets", city],
    mutationFn: getStreets,
    throwOnError: true,
  });
};

export const usePhoneQuery = () => {
  return useQuery({
    queryKey: ["phones"],
    queryFn: getPhones,
    throwOnError: true,
    gcTime: "Infinity",
    staleTime: 1000 * 60,
  });
};

export const useInfoQuery = (code) => {
  return useMutation({
    mutationKey: ["info", code],
    mutationFn: updateInfo,
    throwOnError: true,
  });
};
