import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

import { useEffect } from "react";

// Function to get the image format from base64 string
const getImageFormat = (base64String) => {
  // console.log(base64String);
  // Extract the image format from the base64 string
  const matches = base64String.match(/^data:(image\/(\w+));/);
  // console.log(matches[0], matches[1], matches[2]);
  return matches ? matches[1] : "png"; // Default to PNG if format is not found
};

export default function ImageCropper({
  src,
  show,
  handlePuzzleDataChanged,
  showPreview,
  isPortrait,
  code,
}) {
  const cropperRef = useRef(null);
  const canvasRef = useRef(document.createElement("canvas"));
  // console.log(src);
  useEffect(() => {
    if (showPreview && canvasRef.current) {
      const cropper = cropperRef.current?.cropper;

      if (cropper && src) {
        // console.log("Changing cropped image");
        // console.log(src);
        const srcFormat = getImageFormat(src);
        const croppedBase64 = cropper.getCroppedCanvas()?.toDataURL(srcFormat);
        // console.log(croppedBase64);
        const img = new Image();
        img.src = croppedBase64;

        img.onload = () => {
          const canvas = canvasRef.current;
          const context = canvas.getContext("2d");

          // Set canvas size to match the image
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the original image on the canvas
          context.drawImage(img, 0, 0);

          // Add text with black background and white text
          // const text = code;
          // const fontSize = 30;
          // const textColor = "#ffffff";
          // const bgColor = "#000000";

          // context.font = `${fontSize}px Arial`;
          // context.fillStyle = bgColor;

          // // Calculate text width and height
          // const textWidth = context.measureText(text).width;
          // const textHeight = fontSize;

          // // Position the text at the right bottom corner
          // const x = canvas.width - textWidth - 30;
          // const y = canvas.height - 30;

          // context.fillRect(x, y - textHeight, textWidth + 10, textHeight + 10); // Adding padding to the background
          // context.fillStyle = textColor;
          // context.fillText(text, x + 5, y - 5); // Adjust padding for text

          // const imageFormat = getImageFormat(croppedBase64);
          // console.log(imageFormat);
          // Convert the modified image back to base64
          const modifiedBase64 = canvas.toDataURL(srcFormat);
          // console.log({ modifiedBase64 });
          handlePuzzleDataChanged("base64", modifiedBase64);
        };
      }
    }
  }, [showPreview]);

  // console.log(cropped);
  if (show)
    return (
      <div className="w-full flex flex-col items-center">
        <Cropper
          src={src}
          style={{ width: "100%" }}
          aspectRatio={isPortrait ? 210 / 297 : 297 / 210}
          guides={false}
          key={isPortrait}
          ref={cropperRef}
          dragMode="move"
          background={false}
          cropBoxResizable={false}
          toggleDragModeOnDblclick={false}
          center
        />
        {/* <canvas ref={canvasRef} className="hidden" /> */}
      </div>
    );
  else return null;
}
