import { useState } from "react";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import Logo from "../../components/UI/Logo";
import sendBtn from "../../assests/buttons/Send.png";
import PuzzleImg from "../../assests/backgrounds/puzzle code 1.webp";
import Text1 from "../../assests/texts/Text 1.webp";
export default function Code({ onSend, isPortrait, status }) {
  const [code, setCode] = useState("");
  // console.log(isPortrait);
  return (
    <div className=" flex-1 flex flex-col xs:flex-row justify-center items-center w-full  sm:p-0 ">
      <div className={` ${!isPortrait && "basis-5/12"} `}>
        <div className="w-full flex justify-center items-center h-36 sm:h-60 sm:mb-6">
          <Logo />
        </div>
        {isPortrait && (
          <div className=" w-full flex justify-center items-center">
            <img
              src={PuzzleImg}
              alt=""
              className="h-auto scale-110 -rotate-3"
            />
          </div>
        )}
        <div className="w-full flex flex-col justify-center items-center text-center ">
          <img src={Text1} alt="" className="w-full" />
          <div
            className={` flex justify-center items-center w-4/5 ${
              status != 0 && "hidden"
            }`}
          >
            <Input
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />
          </div>
          <div
            className={`flex justify-center items-center mb-10 sm:mb-0 ${
              status != 0 && "hidden"
            }`}
          >
            <Button btnSrc={sendBtn} onClick={onSend.bind(this, code)} />
          </div>
        </div>
      </div>
      {!isPortrait && (
        <div className="w-full basis-7/12 mt-10">
          <img src={PuzzleImg} className=" -rotate-3 scale-110" alt="" />
        </div>
      )}
    </div>
  );
}
