import { createContext, useState } from "react";

export const UserContext = createContext({
  code: "",
  status: -1,
  updateStatus: (statusCode) => {},
  invoiceData: {},
  puzzleData: {},
  info: {},
  updateInvoice: (data) => {},
  validateInvoice: (data) => {},
  updatePuzzle: (data) => {},
  validatePuzzle: (data) => {},
  updateInfo: (data) => {},
  validateInfo: (data) => {},
});

export default function UserContextProvider({ children }) {
  const [status, setStatus] = useState(-1);
  const [code, setCode] = useState("");
  const [invoiceData, setInvoiceData] = useState({});
  const [puzzleData, setPuzzleData] = useState({});

  const updateCode = (code) => setCode(code);
  const updateStatus = (statusCode) => setStatus(statusCode);
  const updateInvoice = (data) => setInvoiceData(data);
  const updatePuzzle = (data) => setPuzzleData(data);

  const validateInvoice = (data) => {
    if (data) return data.base64 && data.num && data.sum ? true : false;
    return invoiceData.base64 && invoiceData.num && invoiceData.sum
      ? true
      : false;
  };
  const validatePuzzle = (data) => {
    if (data) return data.parts && data.typeId ? true : false;
    return puzzleData.parts && puzzleData.typeId ? true : false;
  };

  const resetUser = () => {
    setStatus(-1);
    setCode("");
    setInvoiceData({});
    setPuzzleData({});
  };

  const value = {
    code,
    status,
    updateStatus,
    invoiceData,
    puzzleData,
    updateCode,
    updateInvoice,
    updatePuzzle,
    validateInvoice,
    validatePuzzle,
    resetUser,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
