import { storage } from "./init";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

export const uploadImage = async (imageData) => {
  try {
    const storageRef = ref(storage, `/puzzle/${imageData.name}`);
    await uploadString(storageRef, imageData.base64, "data_url");
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    throw error;
  }
};
